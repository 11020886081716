const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/about-images.jpg)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Dr. Aaron Myers</h3>
                                <p className="about-text">
                                      Dr. Myers is the CTO of Suited, an A.I. powered, assessment-driven 
                                      recruiting network used by professional services firms to accurately, confidentially, 
                                      and equitably discover and place early career candidates from all background into competitive early-stage career opportunities
                                </p>
                                <p>
                                      Aaron has developed deep expertise in the following areas: deploying machine learning infrastructure to production systems, shepherding companies through the complex process of becoming compliant with information security frameworks, building and managing cross-disciplinary technology teams, navigating funding rounds, communicating and collaborating with a board of directors, and navigating the complicated process of taking an idea from conception to delivered product.
                                </p>
                                <p>
                                      Aaron also engages in adivory services for other startups related to building technology products. He specializes in artificial intelligence supported by his Ph.D. in computational science, engineering, 
                                      and mathematics.
                                </p>
                                <img
                                    className="signechar"
                                    src="./images/signechar2.png"
                                    alt=""
                                />
                                <h6 className="name">Aaron Myers</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
